<template>
    <div>
        <div class="max-w-7xl p-1 lg:m-6 ">

        <InlineNotification Heading="Requests To Play" class="mb-4">
            <template slot="body">
                <p>The artists listed below have requested to play at your venue. Click on 'View profile' to watch and listen to their videos/demos.</p>
                <p>If you like what you see, add them to your favourites lists. If they're not right for your venue you can archive them.</p>
            </template>
        </InlineNotification>
        
        <div class="flex flex-col ">
            <div class="-my-2 overflow-x-visible sm:-mx-6 lg:-mx-8">
                <div class="py-2 align-middle lg:align-baseline inline-block min-w-full sm:px-6 lg:px-8">

                    <div v-show="this.venues.length" class="filter-ctas lg:mx-0 mx-4 flex flex-col lg:flex-row max-w-6xl justify-between items-start md:items-center  my-8">
                        <div class="flex flex-row my-4">
                            <t-toggle name="archived_artist" v-model="showArchived" @change="loadAllRequests" class="mr-4"/>
                            <label>Show Archived Artists</label>
                        </div>
                        <div class="flex items-center">
                            <span class="mr-4">Filter by venue</span>
                            <div>
                                <TSelect class="trichselect thin max-w-sm" v-model="chosenVenue" @change="loadAllRequests"
                                    :options="venues"
                                    text-attribute="name" 
                                    value-attribute="id"  
                                />
                            </div>
                        </div>
                    </div>
                <div class="max-w-6xl align-baseline sm:rounded-lg" v-show="requests.length">
                    <div v-for="(artist, index) in requests" :key="index" class="w-full">
                    <div class="grid grid-cols-2 lg:sm:grid-cols-5 gap-3 p-5 border mt-4 rounded-lg shadow-lg">
                        <div class="relative w-40 h-40">
                            <img :src="artist.image
                                        ? artist.conversions &&
                                            artist.conversions.thumb
                                            ? artist.conversions.thumb
                                            : artist.image.url
                                        : require('@/assets/images/placeholder.svg')"
                                :alt="artist.name"
                                class="object-cover object-center w-40 h-40 border-2 rounded-xl"
                            />
                            <span class="absolute bottom-2 left-2 text-xs font-medium px-2.5 py-0.5 rounded-xl bg-gp_pink-default-500 text-white">
                                {{ artist.tier.name }}
                            </span>
                        </div>
                        <div>
                            <div class="cursor-pointer" @click.prevent="navigate('/artist-directory/' + artist.id + '/' + slugify(artist.name).toLowerCase())">
                                <strong>{{ artist.name }}</strong><br/>
                                <p v-if="artist.basedIn && artist.basedIn.name" class="mt-2 text-xs font-normal">
                                    Based in {{ artist.basedIn.name }}
                                </p>
                            </div>
                            <div class="mt-7 space-y-1 items-center justify-center text-center">
                                <div class="flex space-x-3 items-center mb-2 hover:text-gp_pink-default-500">
                                    <a v-if="favedArtists.includes(artist.id) == false" class="cursor-pointer text-gp_pink-default-500" @click="$refs.faveModal.toggleModal();;addArtist = artist;">
                                    <StarIcon fill="none" stroke="currentColor" title="Add to favourites list" class="h-6 w-6"/>
                                    </a>
                                    <a v-if="favedArtists.includes(artist.id) == true" class="cursor-pointer text-gp_pink-default-500" @click="$refs.faveModal.toggleModal();;addArtist = artist; privateVenues = artist.private_venues; privateVenueIds = setPrivateArtistIds(artist.private_venues)">
                                    <StarIcon title="Already in favourites list" class="h-6 w-6"/>
                                    </a>
                                    <p @click="$refs.faveModal.toggleModal();;addArtist = artist;" class="text-sm font-normal cursor-pointer hover:text-gp_pink-default-500">Add to favourites</p>
                                </div>
                                <div class="flex space-x-4 items-center hover:text-gp_pink-default-500">
                                    <a target="_blank" :href="'/artist-directory/' + artist.id + '/' + slugify(artist.name).toLowerCase()">
                                        <EyeIcon fill="none" stroke="currentColor" class="cursor-pointer text-gp_pink-default-500 h-6 w-5"/>
                                    </a>
                                    <a target="_blank" :href="'/artist-directory/' + artist.id + '/' + slugify(artist.name).toLowerCase()" class="text-sm font-normal">View Profile</a>
                                </div>
                            </div>
                        </div>

                        <div class="col-span-1 lg:col-span-2">
                            <a target="_blank" :href="'/artist-directory/' + artist.id + '/' + slugify(artist.name).toLowerCase()" class="text-sm font-normal">
                                <div v-show="artist.requests.length !== 0" class="mb-6">
                                    <strong class="text-sm font-medium">Requested to play at:</strong>
                                    <div v-for="(venue) in artist.requests" :key="venue.id" class="mt-3">
                                        <li class="text-sm">{{ venue.name }}</li>
                                    </div>
                                </div>
                            </a>
                        </div>

                        <div v-show="!showArchived" @click="archiveArtistRequests(index, artist.id)" class="flex items-center">
                            <button type="button" class="flex items-center text-white bg-gp_pink-default-400 hover:bg-gp_pink-default-300 focus:ring-4 focus:outline-none focus:ring-pink-300 dark:focus:ring-pink-800 font-medium rounded-3xl text-sm px-5 py-1.5 text-center me-2 mb-2">
                                <ArchiveIcon class="mr-2 w-5 h-5"/>Archive Requests
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
        </div>
        <div>
            <NoDataMsgWrapper v-show="isLoaded && !requests.length" class="max-w-4xl">
                    There are no artist requests to show with the selected filters. Please refine your search.
            </NoDataMsgWrapper>
            <NoDataMsgWrapper v-show="!isLoaded" class="max-w-4xl">
                    Loading...
            </NoDataMsgWrapper>
        </div>
        <NotificationSmall ref="notification" />
        <FaveManagement
            ref="faveModal"
            @forcePageUpdate="loadFavourites"
            :Artist="addArtist"
            :private-invites="privateVenues"
            :private-invite-ids="privateVenueIds"
            :private-artist-venues-prop="privateArtistVenues"
            @privateVenueAdded="updatePrivateVenues($event)"
        />
        <FeatureLocked />
        <SaveCard ref="savecardmodal"  />
    </div>
    </div>
</template>

<script>
import client from './../../utils/client';
import NotificationSmall from '../../components/notifications/small.vue';
import { EyeIcon, ArchiveIcon, StarIcon } from '@vue-hero-icons/solid'
import slugify from 'slugify';
import NoDataMsgWrapper from '../../components/ui/messages/NoData.vue';
import NProgress from 'nprogress'
import { TToggle } from 'vue-tailwind/dist/components';
import { TSelect } from 'vue-tailwind/dist/components';
import FaveManagement from '../../components/modal/FaveManagement.vue'
import InlineNotification from '../../components/notifications/inline.vue'
import FeatureLocked from '../../components/modal/FeatureLocked.vue'
import SaveCard from '../../components/modal/venues/subscriptions/SaveCard.vue'

export default {
    name: "RequestsToPlayIndex",
    data(){
        return {
            acc_info: JSON.parse(localStorage.getItem('acc_info')),
            venues: [],
            requests: [],
            showArchived: false,
            chosenVenue: 'all',
            compFaveslists: [],
            favedArtists: [],
            addArtist: {},
            privateVenues: [],
            privateVenueIds: [],
            privateArtistVenues: [],
            isLoaded: false
        }
    },
    components: { FeatureLocked, SaveCard, NotificationSmall, EyeIcon, ArchiveIcon, StarIcon, NoDataMsgWrapper, TToggle, TSelect, FaveManagement, InlineNotification },
    mounted() {
        
        if (!this.acc_info.has_active_free_trial && !this.acc_info.has_active_legacy_subscription && !this.acc_info.has_active_venue_subscription && !this.acc_info.has_active_payg_tokens
            && this.acc_info.venue_count == 1 && this.acc_info.onboarded_using_new_onboard && !this.acc_info.has_active_tokens && !this.acc_info.free_trial_info
        ) {
            this.$refs.savecardmodal.addVenueInfoForTrial({ id: this.acc_info.onboarded_venue.venue_id , name: this.acc_info.onboarded_venue.venue_name });
        }

        this.loadFavourites();
        this.loadVenues();
        this.loadAllRequests();
    },
    watch: {

    },
    computed: {

    },
    methods: {
        slugify,
        loadFavourites(){
            client.get('/private-venues').then(response => (this.privateArtistVenues = response.data.data))

            client.get('/favourites-lists/')
                .then(response => (this.compFaveslists = response.data.data))
                .finally(() => {
                        this.compFaveslists.map((first) => {
                        first.artists.map((result) => {
                            this.favedArtists.push(result.id);
                        })
                    })
                })
        },
        loadVenues(){
            client.get('requests-to-play/venues')
                .then((response) => {
                    this.venues = [{ id:"all", name:"All venues" }, ...response.data.data ];
                })
                .catch((error) => {
                    this.$refs.notification.createNotification(error.response.data.error.message[0], true);
                })
        },
        loadAllRequests() {
            NProgress.start();
            this.requests = [];
            this.isLoaded = false;
            client.get('requests-to-play/all-requests' + '?show_archived=' + this.showArchived + '&venue_id=' + this.chosenVenue)
                .then((response) => {
                    this.requests = response.data.data;
                })
                .finally(() => {
                    this.isLoaded = true;
                    NProgress.done();
                })
        },
        archiveArtistRequests(index, artist_id) {
            NProgress.start();
            client.post('requests-to-play/archive', { artist_id : artist_id })
                .then(() => {
                    this.requests.splice(index, 1);
                    this.$refs.notification.createNotification('Artist requests archived.', false);
                })
                .finally(() => {
                    NProgress.done();
                })
        },
        updatePrivateVenues($event) {
            this.privateVenues = $event;
      },
    }
}
</script>
